@import "../../../styles/2-tools/tools.media-query";

.Footer {
	background-color: var(--color-primary);
	color: var(--color-white);
	width: 100%;
	padding-top: var(--spacing--xl);
	padding-right: var(--spacing--md);
	padding-left: var(--spacing--xl);
	@include mq("md", max) {
		padding-left: var(--spacing--md);
	}
}
